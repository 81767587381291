import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import axios from 'axios';

import { Row } from 'reactstrap';
import Header from '../Components/Header';
import PersonalInformation from '../Components/PersonalInformation';

import { useDispatch, useStore } from '../Stores/FormStore/FormProvider';
import { type } from '../Stores/FormStore/FormReducer';
import { useData, useDataDispatch } from '../Stores/Data/acceleratorContext';
import { dataTypes } from '../Stores/Data/acceleratorReducer';
import { validatePhoneNumber, validateEmail } from '../../helpers';

import {
    learningStyleOptionsState, 
    interestOfApplyingOptionsState,
    firstStepValidation
} from '../Utils/constants';

const StepOne = ({ setPageHeight }) => {
    const dataDispatch = useDataDispatch();
    const data = useData();
    const dispatch = useDispatch();
    const { currentStep, errorMessage } = useStore();
    const pageDisabled = currentStep !== 0;

    const [validInput, setValidInput] = useState({...firstStepValidation});
    const [touchedInput, setTouchedInput] = useState({ ...firstStepValidation });
    const [interestOfApplyingState, setInterestOfApplyingState] = useState({...interestOfApplyingOptionsState});
    const [learningStyleState, setLearningStyleState] = useState({...learningStyleOptionsState});

    const onBlur = (event) => {
        !touchedInput[event.target.name] && setTouchedInput({ ...touchedInput, [event.target.name]: true });
        let isValid = true;
        isValid = event.target.value.trim() !== '';
        setValidInput({ ...validInput, [event.target.name]: isValid });
    }

    useEffect(() => !pageDisabled && setPageHeight('step1'));

    useEffect(() => {
        const insterestSelected = Object.keys(interestOfApplyingState).filter(filterName => interestOfApplyingState[filterName]);
        dataDispatch({ type: dataTypes.setInteresInApplying, payload: insterestSelected});
        insterestSelected.length >= 1 ? 
        setValidInput({ ...validInput, interest_in_applying: true}) :
        setValidInput({ ...validInput, interest_in_applying: false})
    }, [interestOfApplyingState]);

    useEffect(() => {
        const learningStyleSelected = Object.keys(learningStyleState).filter(filterName => learningStyleState[filterName]);
        dataDispatch({ type: dataTypes.setLearningStyle, payload: learningStyleSelected});
    }, [learningStyleState]);

    const handleInterestOfApplying = (e) => {
        const { name, checked } = e.target;
        setInterestOfApplyingState({
            ...interestOfApplyingState,
            [name]: checked
        });
    }

    const handleLearningStyles = (e) => {
        const { name, checked } = e.target;
        setLearningStyleState({
            ...learningStyleState,
            [name]: checked
        })
    }
    
    const handleOnClick = async (e) => {
        e.preventDefault();
        dispatch({ type: type.toggleIsLoading });
        try {
            const response = await API.post('enrichHer-mvp', 'acceleratorApplication', { 
                body: data 
            });
    
            if (response) {
                const URL = process.env['REACT_APP_SLACK_NOTIFICATION_SUBMIT_ZAP'];
                const body = {
                    email: data.email,
                    first_name: data.first_name, 
                    last_name: data.last_name,
                    phone_number: data.phone_number,
                    form: "EnrichHER Accelerator",
                    status: 'Opt-in',
                    message: 'just completed the first step of the following quiz'
                }
                axios.post(URL, JSON.stringify({ body }));

                dispatch({ type: type.clearErrorMessage });
                dispatch({ type: type.toggleIsLoading });
                dispatch({ type: type.nextStep });
            }
        } catch {
            dispatch({ type: type.toggleIsLoading });
            dispatch({ type: type.setErrorMessage, payload: 'There was an error. Please try again.' });
        }
    }

    const stepCompleted = () => validatePhoneNumber(data.phone_number) && validateEmail(data.email) && Object.values(validInput).every(value => value === true);
    const requiredFields = validatePhoneNumber(data.phone_number) && validateEmail(data.email) && Object.keys(validInput).filter(filterName => validInput[filterName]);

    return (
        <div className={`fragment-form-application ${pageDisabled && 'inactive'}`}>
            <div className='form' id='step1'>
                <Row>
                    <Header />
                    <PersonalInformation 
                    data={data} 
                    dataDispatch={dataDispatch}
                    dataTypes={dataTypes}
                    touchedInput={touchedInput}
                    validInput={validInput}
                    onBlur={onBlur}
                    handleLearningStyles={handleLearningStyles}
                    handleInterestOfApplying={handleInterestOfApplying}
                    handleOnClick={handleOnClick}
                    stepCompleted={stepCompleted}
                    requiredFields={requiredFields}
                    />
                </Row>
                <p className='error-input' style={{ textAlign: 'right' }}>{ errorMessage }</p>
                <p className='error-input' style={{ textAlign: 'right' }}>{requiredFields.length === 6 ? '' : 'Please complete all required fields above in order to continue'}</p>
            </div>
        </div>
    )
}

export default StepOne
