export function formatAmount(amount, decimalCount = 2, thousands = ',') {
  const negativeSign = amount < 0 ? '-' : '';
  let i = parseFloat(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
  var res = i.split('.');
  if (res.length == 1 || res[1].length < 3) {
    i = parseFloat(i).toFixed(decimalCount);
  }
  let j = (i.length > 3) ? i.length % 3 : 0;

  return res[0] == '0' ? parseFloat(i).toFixed(decimalCount) : negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
}

export function validatePhoneNumber(phone) {
  const phoneRegex = /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
  return phoneRegex.test(phone);
}

export function validateWebsite(websiteurl) {
  const websiteRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return websiteRegex.test(websiteurl);
}

export const validateEmail = (email) => {
  const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return mailFormat.test(email)
}

export const optionsTimeInBusiness = [
  { value: 'Less than 6 months', label: 'Less than 6 months' },
  { value: 'More than 6 months, but less than 1 year', label: 'More than 6 months, but less than 1 year' },
  { value: 'More than 1 year, but less than 2 years', label: 'More than 1 year, but less than 2 years' },
  { value: 'More than 2 years', label: 'More than 2 years' }];

export const optionsRevenue = [
  { value: 'Less than $25K', label: 'Less than $25K' },
  { value: 'Between $25K and $50K', label: 'Between $25K and $50K' },
  { value: 'Between $50K and $100K', label: 'Between $50K and $100K' },
  { value: 'Between $100K and $250K', label: 'Between $100K and $250K' },
  { value: 'Between $250K and $500K', label: 'Between $250K and $500K' },
  { value: 'Between $500K and $1 Million', label: 'Between $500K and $1 Million' },
  { value: 'More than $1 Million', label: 'More than $1 Million' }];

export const optionsSocialMediaChanels = [
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Snapchat', label: 'Snapchat' },
  { value: 'TikTok', label: 'TikTok' },
  { value: 'Blog', label: 'Blog' },
  { value: 'E-mail marketing', label: 'E-mail marketing' },
  { value: 'Organic or SEO', label: 'Organic or SEO' },
  { value: 'Paid Search,', label: 'Paid Search' },
  { value: 'Content Marketing', label: 'Content Marketing' },
  { value: 'Affiliates', label: 'Affiliates' },
  { value: 'Referrals', label: 'Referrals' },
  { value: 'Direct', label: 'Direct' }];

export const optionsHowDidYouHearAboutUs = [
  { value: 'EnrichHER Website', label: 'EnrichHER Website' },
  { value: 'Hello Alice', label: 'Hello Alice' },
  { value: 'Founders of Color', label: 'Founders of Color' },
  { value: 'Biz Mujer', label: 'Biz Mujer' },
  { value: 'Black Innovation Alliance', label: 'Black Innovation Alliance' },
  { value: 'Clubhouse', label: 'Clubhouse' },
  { value: 'Center for Civic Innovation', label: 'Center for Civic Innovation' },
  { value: 'Tory Burch Foundation', label: 'Tory Burch Foundation' },
  { value: 'Intentional Life Club', label: 'Intentional Life Club' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Podcast', label: 'Podcast' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Linkedin', label: 'Linkedin' },
  { value: 'Google', label: 'Google' },
  { value: 'Articles', label: 'Articles' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Events', label: 'Events' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Publication', label: 'Publication' },
  { value: 'News Article', label: 'News Article' },
  { value: 'Other', label: 'Other' }];

export const optionsBusinessYears = [
  {
    value: 0, label: '0 years',
  },
  {
    value: 0.5, label: '0.5 years',
  },
  {
    value: 1, label: '1 year',
  },
  {
    value: 1.5, label: '1.5 year',
  }, {
    value: 2, label: '2+ years',
  },
];