import React from 'react';
import 'react-rangeslider/lib/index.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './Styles/css/bootstrap.min.css';
import './Styles/index.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import FormProvider from './Accelerator/Stores/FormStore/FormProvider';
import AcceleratorApplication from './Views/AcceleratorApplication';
import AcceleratorProvider  from './Accelerator/Stores/Data/acceleratorContext';

function App() {
  return (
    <div className='App'>
      <Router>
          <FormProvider>
            <AcceleratorProvider>
              <Route path='/' component={AcceleratorApplication} />
            </AcceleratorProvider>
          </FormProvider>
      </Router>
    </div>
  );
}

export default App;
