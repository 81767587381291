import React from 'react';

import { Col, Label, FormText, Row } from 'reactstrap';
import { ERHInput } from '../../Components/Input';
import { ERHCheckbox } from '../../Components/Checkbox';
import { ERHSelect } from '../../Components/Select';
import { ERHButton } from '../../Components/Button';

import { socialMediaOptions } from '../Utils/constants';
import { interestOfApplying, learningStyle } from '../Utils/constants';
import { optionsHowDidYouHearAboutUs, validatePhoneNumber, validateEmail } from '../../helpers';

const PersonalInformation = ({
    data,
    dataDispatch,
    dataTypes,
    touchedInput,
    validInput,
    onBlur,
    handleInterestOfApplying,
    handleLearningStyles,
    handleOnClick,
    stepCompleted
}) => {
    return (
        <>
            <Col xs={12} sm={6}>
                <ERHInput
                name='first_name'
                type='text'
                value={data.first_name}
                id='first_name'
                label='First name*'
                placeholder='Your first name'
                classContainer='my-2'
                onChange={(e) => dataDispatch({ type: dataTypes.setFirstName, payload: e.target.value })}
                onBlur={onBlur}
                autoComplete='disabled'
                error={touchedInput.first_name && !validInput.first_name && 'Please enter a valid name.'}
                />
            </Col>
            <Col xs={12} sm={6}>
                <ERHInput
                name='last_name'
                type='text'
                value={data.last_name}
                id='last_name'
                label='Last name*'
                placeholder='Your last name'
                classContainer='my-2'
                onChange={(e) => dataDispatch({ type: dataTypes.setLastName, payload: e.target.value })}
                onBlur={onBlur}
                autoComplete='disabled'
                error={touchedInput.last_name && !validInput.last_name && 'Please enter a valid last name.'}
                />
            </Col>
            <Col xs={12} sm={6}>
                <ERHInput
                name='business_name'
                type='text'
                value={data.business_name}
                id='business_name'
                label='Business name*'
                placeholder='Your business name'
                classContainer='my-2'
                onChange={(e) => dataDispatch({ type: dataTypes.setBusinessName, payload: e.target.value })}
                onBlur={onBlur}
                autoComplete='disabled'
                error={touchedInput.business_name && !validInput.business_name && 'Please enter a valid business name.'}
                />
            </Col>
            <Col xs={12} sm={6}>
                <ERHInput
                name='email'
                type='text'
                value={data.email}
                id='email'
                label='Email address*'
                placeholder='Your email address'
                classContainer='my-2'
                onChange={(e) => dataDispatch({ type: dataTypes.setEmail, payload: e.target.value })}
                onBlur={onBlur}
                autoComplete='disabled'
                error={!validateEmail(data.email) && touchedInput.email && 'Please enter a valid email address'}
                />
            </Col>
            <Col xs={12} sm={6}>
                <ERHInput
                name='phone_number'
                type='text'
                value={data.phone_number}
                id='phone_number'
                label='Mobile phone number*'
                placeholder='Your phone number'
                classContainer='my-2'
                onChange={(e) => dataDispatch({ type: dataTypes.setPhoneNumber, payload: e.target.value })}
                onBlur={onBlur}
                autoComplete='disabled'
                error={!validatePhoneNumber(data.phone_number) && touchedInput.phone_number && 'Please enter a valid phone number'}
                />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your interest in applying for the EnrichHER business financing accelerator?*</Label>
                <FormText tag='medium' className='mt-0'>Select all that apply</FormText>
                { interestOfApplying.map(({name, label})=> (
                    <ERHCheckbox
                        name={name}
                        key={name}
                        label={label}
                        id={name}
                        classContainer={'my-2 px-3'}
                        styled={true}
                        onChange={handleInterestOfApplying}
                    />
                ))}
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your preferred learning style?</Label>
                <FormText tag='medium' className='mt-0'>Select all that apply</FormText>
                    { learningStyle.map(({name, label}) => (
                        <ERHCheckbox
                        classContainer={'my-2 px-3'}
                        label={label}
                        name={name}
                        id={name}
                        styled={true}
                        onChange={handleLearningStyles}
                        />
                    ))}
            </Col>
            
            <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
                    <ERHButton
                        className='my-2'
                        onClick={e => {
                            e.preventDefault()
                        }}
                        outline
                    >
                        Cancel
                    </ERHButton>
                    <ERHButton
                        className='my-2'
                        onClick={handleOnClick}
                        disabled={!stepCompleted()}
                    >
                        Continue
                    </ERHButton>
                </div>
        </>
    )
}

export default PersonalInformation
