export const steps = ['Getting to Know You', 'More About You', 'Acknowledgements & Next Steps'];

export const firstStepValidation = {
    email: false,
    first_name: false,
    last_name: false,
    phone_number: false,
    business_name: false,
    interest_in_applying: false
}

export const secondStepValidation = {
    years_in_business: false,
    credit_score: true,
    amount_requested: true,
    annual_revenue: false,
    type_of_funding: false,
    social_media: false
}

export const thirdStepValidation = {
    application_fee: false,
    society_membership: false,
    expectations_and_requirements: false,
    eligibility_and_requirements: false,
    right_to_apply: false,
}

export const interestOfApplying = [
    {
        name: 'EnrichHER Business Financing Accelerator - Learn How to Make $100K+.',
        label: 'EnrichHER Business Financing Accelerator - Learn How to Make $100K+.'
    },
    {
        name: 'Funder Matching - Gain Money for My Business.',
        label: 'Funder Matching - Gain Money for My Business.'
    },
    {
        name: 'EnrichHER Society Membership - Coaching, Capital, and Connections.',
        label: 'EnrichHER Society Membership - Coaching, Capital, and Connections.'
    },
    {
        name: 'Coaching From Dr. Roshawnna Novellus and the EnrichHER Team.',
        label: 'Coaching From Dr. Roshawnna Novellus and the EnrichHER Team.'
    }
];

export const typeOfFunding = [
    {
        name: 'Grants',
        label:' Grants'
    },
    {
        name: 'Loans',
        label: 'Loans'
    },
    {
        name: 'Equity',
        label: 'Equity'
    }
];

export const learningStyle = [
    {
        name: 'Visual | Visual learners need to see simple, easy-to-process diagrams or the written word.',
        label: 'Visual | Visual learners need to see simple, easy-to-process diagrams or the written word.'
    },
    {
        name: 'Aural | Aural learners need to hear something so that it can be processed.',
        label: 'Aural | Aural learners need to hear something so that it can be processed.'
    },
    {
        name: 'Print | Print learners process information by writing it down.',
        label: 'Print | Print learners process information by writing it down.'
    },
    {
        name: 'Tactile | Tactile learners need to do something in order to learn it.',
        label: 'Tactile | Tactile learners need to do something in order to learn it.'
    },
    {
        name: 'Interactive | Interactive learners need to discuss learning concepts',
        label: 'Interactive | Interactive learners need to discuss learning concepts'
    },
    {
        name: 'Kinesthetic | Kinesthetic learners learn through movement.',
        label: 'Kinesthetic | Kinesthetic learners learn through movement.'
    },

];

export const socialMediaOptionsState = {
    enrichher_newsletter: false,
    enrichher_social_media_post: false,
    enrichher_email: false,
    word_or_mouth: false,
    partner_organization_affiliated_with_enrichher: false,
    other: false
};

export const interestOfApplyingOptionsState = {
    enrichHER_business_financing_accelerator_learn_how_to_make_$100k: false,
    funder_matching_gain_money_for_my_business: false,
    enrichHER_society_membership_coaching_capital_and_connections: false,
    coaching_from_dr_Roshawnna_Novellus_and_the_enrichHER_team: false
};

export const learningStyleOptionsState = {
    visual_learners_need_to_see_simple_easy_to_process_diagrams_or_the_written_word: false,
    aural_learners_need_to_hear_something_so_that_it_can_be_processed: false,
    print_learners_process_information_by_writing_it_down: false,
    tactile_learners_need_to_do_something_in_order_to_learn_it: false,
    interactive_learners_need_to_discuss_learning_concepts: false,
    kinesthetic_learners_learn_through_movement: false
};

export const typeOfFundingOptionsState = { 
    grants: false,
    loans: false,
    equity: false
};

export const socialMediaOptions = [
    {
        name: 'EnrichHER Newsletter',
        label: 'EnrichHER Newsletter',
        key: 'enrichher_newsletter'
    },
    {
        name: 'EnrichHER Social Media Post (Linkedin, Facebook, Instagram, Twitter)',
        label: 'EnrichHER Social Media Post (Linkedin, Facebook, Instagram, Twitter)',
        key: 'enrichher_social_media'
    },
    {
        name: 'EnrichHER Email',
        label: 'EnrichHER Email',
        key: 'enrichher_email'
    },
    {
        name: 'Word-of-mouth',
        label: 'Word-of-mouth',
        key: 'word_of_mouth'
    },
    {
        name: 'Partner Organization Affiliated with EnrichHER (HelloAlice, Women 2.0...)',
        label: 'Partner Organization Affiliated with EnrichHER (HelloAlice, Women 2.0...)',
        key: 'partner_organization_affiliated_with_enrichher'
    },
    {
        name: 'Other',
        label: 'Other',
        key:'other_source'
    }
]
