import React, { useRef, useEffect } from 'react';
import Paginate from '../../Components/Forms/FormApplication/Paginate';
import { PersonalInformation, BusinessInformation, TermsAndConditions } from '../Containers';
import { steps } from '../Utils/constants';
import { useStore } from '../Stores/FormStore/FormProvider';
const ERHAccelerator = () => {
    const refWrapper = useRef()
    const { currentStep } = useStore();
    
    useEffect(() => {
        if (refWrapper) {
            document.querySelector('body').scrollTo(0, 0)
            refWrapper.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [currentStep]);

    const setPageHeight = (pageId) => {
        const formHeight = document.getElementById(pageId).offsetHeight
        document.getElementById('scope-form').style.height = `${formHeight}px`
    }

    return (
        <>
        <Paginate steps={steps}  currentStep={currentStep}/>
        <div ref={refWrapper} className='container-form-application'>
            <form className='my-3'>
                <div className='wrapper-forms'>
                    <div className={'scope-form'} id='scope-form' style={{ transform: `translate3d(${-currentStep * 100}%,0,0)` }}>
                        <PersonalInformation setPageHeight={setPageHeight} />
                        <BusinessInformation setPageHeight={setPageHeight} />
                        <TermsAndConditions setPageHeight={setPageHeight} />
                    </div>
                </div>
            </form>
        </div>
        </>
    )
}

export default ERHAccelerator
