import React, { useState, useLayoutEffect } from 'react';


const DocusignClickWrap = ({agreeDocusign, setAgreeDocusign, DocusignId, email}) => {
  const [mustAgree, setMustAgree] = useState(false);

  useLayoutEffect(() => {
    window.docuSignClick.Clickwrap.render({
      environment: process.env['REACT_APP_DOCUSIGN_CLICKWRAP_SDK_SOURCE'],
      accountId: process.env['REACT_APP_DOCUSIGN_ACC_ID'],
      clickwrapId: process.env['REACT_APP_DOCUSIGN_CLICKWRAP'],
      clientUserId: email,

      onMustAgree(agreement) {
        setMustAgree(true);
        setAgreeDocusign(false);
      },

      onAgreed(agreement) {
        setAgreeDocusign(true);
      },

      onDeclined(agreement) {
        
      }
    }, `#${DocusignId}`);

  }, [email]);

  return (
    <>
      <div id={DocusignId} />
      {!mustAgree && <p className='label-input mb-0 mt-2'>Terms already accepted, please continue.</p>}
    </>
  );
};

export default DocusignClickWrap;