const dataTypes = {
    setEmail: 'setEmail',
    setFirstName: 'setFirstName',
    setLastName: 'setLastName',
    setPhoneNumber: 'setPhoneNumber',
    setBusinessName: 'setBusinessName',
    setSocialMedia: 'setSocialMedia',
    setSocialMediaChannels: 'setSocialMediaChannels',
    setYearsInBusiness: 'setYearsInBusiness',
    setCreditScore: 'serCreditScore',
    setAmountRequested: 'setAmountRequested',
    setAnnualRevenue: 'setAnnualRevenue',
    setInteresInApplying: 'setInterestInApplying',
    setTypeOfFunding: 'setTypeOfFunding',
    setLearningStyle: 'setLearningStyle',
    setApplicationFee: 'setApplicationFee',
    setSocietyMembership: 'setSocietyMembership',
    setExpectationsAndRequirements: 'setExpectationsAndRequirements',
    setEligibilityAndRequirements: 'setEligibilityAndRequirements',
    setTermsAndConditions: 'setTermsAndConditions',
    setTermsAndConditions: 'setTermsAndConditions',
    setRightToApply: 'setRightToApply',
    setWailist: 'setWaitlist',
    setNextSteps: 'setNextSteps',
    setWebsite: 'setWebsite',
    setBusinessInstagram: 'setBusinessInstagram',
    setBusinessFacebook: 'setBusinessFacebook',
    setBusinessLinkedin: 'setBusinessLinkedin',
    setPersonalInstagram: 'setPersonalInstagram',
    setOnlineResource: 'setOnlineResource',
    setPersonalFacebook: 'setPersonalFacebook',
    setPersonalLinkedin: 'setPersonalLinkedin',
    setUTMVariables: 'setUTMVariables',
}

const initialState = {
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    business_name: "",
    social_media: null,
    social_media_channels: [],
    years_in_business: "",
    credit_score: 760,
    amount_requested: 50000,
    annual_revenue: "",
    interest_in_applying: null,
    type_of_funding: null,
    learning_style: null,
    application_fee: true,
    society_membership: true,
    expectations_and_requirements: true,
    eligibility_and_requirements: true,
    right_to_apply: true,
    terms_and_conditions: true,
    next_steps: false,
    website: '',
    business_instagram: '',
    business_facebook: '',
    business_linkedin: '',
    personal_instagram: '',
    online_resource: '',
    personal_facebook: '',
    personal_linkedin: '',
    utm_variables: null
}

const dataReducer = (state, action) => {
    switch (action.type) {
        case dataTypes.setEmail:
            return {
                ...state,
                email: action.payload
            }

        case dataTypes.setFirstName:
            return {
                ...state,
                first_name: action.payload
            }

        case dataTypes.setLastName:
            return {
                ...state,
                last_name: action.payload
            }

        case dataTypes.setPhoneNumber:
            return {
                ...state,
                phone_number: action.payload
            }

        case dataTypes.setBusinessName:
            return {
                ...state,
                business_name: action.payload
            }
            
        case dataTypes.setSocialMedia:
            return {
                ...state,
                social_media: action.payload
            }
        case dataTypes.setSocialMediaChannels:
            return {
                ...state,
                social_media_channels: action.payload
            }
        case dataTypes.setYearsInBusiness:
            return {
                ...state,
                years_in_business: action.payload
            }

        case dataTypes.setCreditScore:
        return {
            ...state,
            credit_score: action.payload
        }

        case dataTypes.setAmountRequested:
            return {
                ...state,
                amount_requested: action.payload
            }

        case dataTypes.setAnnualRevenue:
            return {
                ...state,
                annual_revenue: action.payload
            }

        case dataTypes.setWebsite:
        return {
            ...state,
            website: action.payload
        }

        case dataTypes.setBusinessFacebook:
            return {
                ...state,
                business_facebook: action.payload
            }
        case dataTypes.setBusinessInstagram:
            return {
                ...state,
                business_instagram: action.payload
            }

        case dataTypes.setBusinessLinkedin:
            return {
                ...state,
                business_linkedin: action.payload
            }

        case dataTypes.setPersonalInstagram:
            return {
                ...state,
                personal_instagram: action.payload
            }

        case dataTypes.setPersonalFacebook:
            return {
                ...state,
                personal_facebook: action.payload
            }

        case dataTypes.setPersonalLinkedin:
            return {
                ...state,
                personal_linkedin: action.payload
            }

        case dataTypes.setOnlineResource:
            return {
                ...state,
                online_resource: action.payload
            }

        case dataTypes.setInteresInApplying:
            return {
                ...state,
                interest_in_applying: action.payload
            }

        case dataTypes.setTypeOfFunding:
            return {
                ...state,
                type_of_funding: action.payload
            }

        case dataTypes.setLearningStyle:
            return {
                ...state,
                learning_style: action.payload
            }

        case dataTypes.setApplicationFee:
            return {
                ...state,
                application_fee: action.payload
            }

        case dataTypes.setSocietyMembership:
            return {
                ...state,
                society_membership: action.payload
            }

        case dataTypes.setExpectationsAndRequirements:
            return {
                ...state,
                expectations_and_requirements: action.payload
            }

        case dataTypes.setEligibilityAndRequirements:
            return {
                ...state,
                eligibility_and_requirements: action.payload
            }
    
        case dataTypes.setRightToApply:
            return {
                ...state,
                right_to_apply: action.payload
            }

        case dataTypes.setTermsAndConditions:
            return {
                ...state,
                terms_and_conditions: action.payload
            }

        case dataTypes.setNextSteps:
            return {
                ...state,
                next_steps: action.payload
            }

        case dataTypes.setUTMVariables:
            return {
                ...state,
                utm_variables: action.payload
            }
            
        default:
            return state
    }
}

export { initialState, dataTypes }
export default dataReducer;