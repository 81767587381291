import React from 'react';
import { Col, Label, FormText, Row } from 'reactstrap';
import { ERHButton } from '../../Components/Button';
import { ERHCheckbox } from '../../Components/Checkbox';
import DocusingClickWrap from '../../Components/DocusignClickWrap';

const ApplicationFee = ({
    stepCompleted,
    handleCheckboxes,
    goBack,
    onSubmit,
    handleNextStep,
    agreeDocusign,
    setAgreeDocusign,
    email
}) => {
    return (
        <>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Enrollment Cost *</Label>                     
                    <FormText tag='medium'>The cost to enroll in this program is $497 or 3 payments of $197 and includes: 
                    EnrichHER Society Membership ($497 per year) as well as registration for the EnrichHER Business Financing Accelerator program 
                    (a $999 value where you will learn how to make over $100K in capital).
                    </FormText>
                        <Col xs={12} className='mt-1'>
                            <ERHCheckbox 
                                name='application_fee'
                                id='application_fee'
                                classContainer={'my-1 px-3'}
                                styled={true}
                                label='Check the box to acknowledge that you are aware of and agree to the fee.'
                                onChange={handleCheckboxes}
                            />
                        </Col>
                </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>EnrichHER Society Membership*</Label>
                    <FormText tag='medium'>Once you complete this application and pay the fee, you will be required to create an account on EnrichHER Society.</FormText>
                        <Col xs={12} className='mt-1'>
                        <ERHCheckbox 
                            name='society_membership'
                            id='society_membership'
                            classContainer={'my-1 px-3'}
                            styled={true}
                            label='Check the box to acknowledge that you understand and agree.'
                            onChange={handleCheckboxes}
                        />
                        </Col>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Participation Expectations and Requirements Acknowledgement*</Label>
                    <Col xs={12} className='mt-1'>
                    <ERHCheckbox 
                        name='expectations_and_requirements'
                        id='expectations_and_requirements'
                        classContainer={'my-1 px-3'}
                        styled={true}
                        label='Check the box to acknowledge that you understand and agree to the following expectations as part of this program:'
                        onChange={handleCheckboxes}
                    />
                    </Col>
                    <div className='list'>
                    <ol>
                        <li>Attending class sessions on time or reviewing recorded lectures within 72 hours</li>
                        <li>Completing homework assignments on time</li>
                        <li>Completing a survey at the conclusion of the program</li>
                        <li>Submitting a Trustpilot review at the conclusion of the program</li>
                    </ol>
                    </div>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Participation Eligibility Requirements Acknowledgement*</Label>
                    <Col xs={12} className='mt-1'>
                    <ERHCheckbox 
                        name='eligibility_and_requirements'
                        id='eligibility_and_requirements'
                        classContainer={'my-1 px-3'}
                        styled={true}
                        label='Check the box to acknowledge that each participant will have to complete the following as part of this program:'
                        onChange={handleCheckboxes}
                    />
                    </Col>
                    <div className='list'>
                    <ol>
                        <li>All requested promotional assets by the deadline provided</li>
                        <li>All social media assignments by the deadline provided</li>
                        <li>Submit a Trustpilot review at the conclusion of the program</li>
                    </ol>
                    </div>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Affirmation of Right to Apply*</Label>
                    <Col xs={12} className='mt-1'>
                    <ERHCheckbox 
                        name='right_to_apply'
                        id='right_to_apply'
                        classContainer={'my-1 px-3'}
                        styled={true}
                        label='By applying to the EnrichHER Accelerator + Funder Matching Program, you affirm and acknowledge that:'
                        onChange={handleCheckboxes}
                    />
                    </Col>
                    <div className='list'>
                    <ol>
                        <li>You are authorized to submit this application on behalf of your company</li>
                        <li>The information you have provided is accurate and current</li>
                        <li>Your company either owns or has a license to use the product, service or technology it provides</li>
                        <li>Submitting an application does not guarantee financial investment by EnrichHER or its affiliates.</li>
                    </ol>
                    </div>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Terms & Conditions*</Label>
                {email && <DocusingClickWrap email={email} agreeDocusign={agreeDocusign} setAgreeDocusign={setAgreeDocusign} DocusignId='clickwrap' />}
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Next Steps</Label>
                    <FormText tag='medium'>After clicking "Submit and Proceed to Next Step," you'll be taken to a checkout page to submit your $497 OR 
                    3 payments $197 fee. Note that your application is not considered complete, and your seat will not be reserved, until the fee is paid.
                    </FormText>
                    <FormText tag='medium' className='mt-4'>Once the fee is submitted, you will be enrolled into the Business Financing Accelerator.</FormText>
                        <Col xs={12} className='mt-1'>
                        <ERHCheckbox 
                            name='next_steps'
                            id='next_steps'
                            classContainer={'my-1 px-3'}
                            styled={true}
                            label='Check the box to confirm that you understand.'
                            onChange={handleNextStep}
                        />
                        </Col>
            </Col>
            <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
            <ERHButton
                className='my-2'
                onClick={goBack}
                disabled={false}
            >
                Go back
            </ERHButton>
            <ERHButton
                className='my-2 px-3'
                style={{width: 'auto'}}
                disabled={!stepCompleted()}
                onClick={onSubmit}
            >
                Submit and proceed to next step
            </ERHButton>
        </div>
        </>
    )
}

export default ApplicationFee
