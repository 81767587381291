import React, { useEffect } from 'react';
import ERHAccelerator from '../Accelerator/Layouts/ERHAccelerator';
import { DefaultLayout as Layout } from '../Layouts/DefaultLayout';

import { useStore } from '../Accelerator/Stores/FormStore/FormProvider';
import { useDataDispatch } from '../Accelerator/Stores/Data/acceleratorContext';
import { dataTypes } from '../Accelerator/Stores/Data/acceleratorReducer';
import { AnimatePresence } from 'framer-motion';
import { LoadingPage } from '../Components/Loading';

const AcceleratorApplication = () => {
    const { isLoading } = useStore();
    const dataDispatch = useDataDispatch();
    
    useEffect(() => {
        let params = window.location.search.substring(1).split('&')
        let savedValue = {}
    
        for(let param of params){
        let [ name, value ] = param.split('=');
        savedValue[name] = value ? value : '';
        } 
        
        dataDispatch({ type: dataTypes.setUTMVariables, payload: savedValue});
    }, []);

    return (
        <>
            <AnimatePresence>
                {isLoading && <LoadingPage isLoad={isLoading} />}
            </AnimatePresence>
            <Layout className='view-application' containerFluid={false}>
                <div className='p-1'>
                    <h1 className='mb-2 mt-4'>EnrichHER Business Financing Accelerator Application</h1>
                    <ERHAccelerator />  
                </div>
            </Layout>
        </>
    )
}

export default AcceleratorApplication;
