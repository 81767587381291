import React from 'react';
import { Col } from 'reactstrap';

const Header = () => {
    return (
        <Col xs={12}>
            <h3 className='title-form mb-3'>EnrichHER Business Financing Accelerator Application</h3> 
                <div className={'introductory-div'}>
                    <p>
                    EnrichHER is excited to announce that the Business Financing Accelerator's next cohort is now open for enrollment. 
                    In this five-week virtual course, you will learn how to connect with over $100,000 in funding through grants, loans, 
                    venture capitalists, and other untapped sources. In the last cohort, one participant raised $24,000 in grants alone using the 
                    tools and training she gained from the accelerator.  
                    </p>

                    <p>
                    Whether your business is already established or still an idea, this accelerator can help you access the resources 
                    you need to take your business to the next level. 
                    </p> 

                    <p>
                    Through this application, we are selecting a cohort of New Majority businesses for our upcoming accelerator. 
                    Complete this application to be a part of this program. 
                    </p>
                    
                    <p>
                    Once you complete this form, you will be redirected to a page to pay the application fee. 
                    Note that your enrollment will not be considered complete until payment is submitted.
                    </p>
                </div>
                <hr/>
        </Col>
    )
}



export default Header
