import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import axios from 'axios';
import { Row } from 'reactstrap';
import ApplicationFee from '../Components/ApplicationFee';
import { useDispatch, useStore } from '../Stores/FormStore/FormProvider';
import { useData, useDataDispatch } from '../Stores/Data/acceleratorContext';
import { type } from '../Stores/FormStore/FormReducer';
import { dataTypes } from '../Stores/Data/acceleratorReducer';
import { thirdStepValidation } from '../Utils/constants';

const StepThree = ({ setPageHeight }) => {
    const data = useData();
    const dispatch = useDispatch();
    const { currentStep, errorMessage } = useStore();
    const dataDispatch = useDataDispatch();
    const pageDisabled = currentStep !== 2;
    const [validInput, setValidInput] = useState({...thirdStepValidation});
    const [agreeDocusign, setAgreeDocusign] = useState(false);

    useEffect(() => !pageDisabled && setPageHeight('step3'));

    const handleCheckboxes = (e) => {
        const { name, checked } = e.target;
        setValidInput({ ...validInput, [name]: checked });
    }

    const handleNextStep = (e) => {
        const { checked } = e.target;
        dataDispatch({ type: dataTypes.setNextSteps, payload: checked});
    }
    
    const stepCompleted = () => agreeDocusign && Object.values(validInput).every(value => value === true);

    const goBack = (e) => {
        e.preventDefault()
        dispatch({ type: type.prevStep })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        dispatch({ type: type.toggleIsLoading });
        try {
            const response = await API.post('enrichHer-mvp', 'saveAcceleratorApplication', {
                body: data
            })
            if (response) {
                dispatch({ type: type.toggleIsLoading });
                const URL = process.env['REACT_APP_SLACK_NOTIFICATION_SUBMIT_ZAP'];
                const body = {
                    email: data.email,
                    first_name: data.first_name, 
                    last_name: data.last_name,
                    linkedin: data.personal_linkedin,
                    phone_number: data.phone_number,
                    form: "EnrichHER Accelerator",
                    status: 'Registration Complete',
                    message: 'just completed the following quiz'
                }
                axios.post(URL, JSON.stringify({ body }));

                const { REACT_APP_WP_URL: url } = process.env;
                const path = '/login?referrer=accelerator-form'
                window.location.replace('https://' + url + path);
            }
        } catch {
            dispatch({ type: type.toggleIsLoading });
            dispatch({ type: type.setErrorMessage, payload: 'There was an error. Please try again.' });
        }
    }

    return (
        <div className={`fragment-form-application ${pageDisabled && 'inactive'}`}>
            <div className='form' id='step3'>
                <Row>
                    <ApplicationFee 
                        stepCompleted={stepCompleted}
                        handleCheckboxes={handleCheckboxes}
                        handleNextStep={handleNextStep}
                        onSubmit={onSubmit}
                        goBack={goBack}
                        email={data.email}
                        agreeDocusign={agreeDocusign}
                        setAgreeDocusign={setAgreeDocusign}
                    />
                </Row>
                <p className='error-input' style={{ textAlign: 'right' }}>{ errorMessage }</p>
                <p className='error-input' style={{ textAlign: 'right' }}>{stepCompleted() ? '' : 'Please complete all required fields above in order to continue'}</p>
            </div>
        </div>
    )
}

export default StepThree
