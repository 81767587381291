import React from 'react';
import { Col, Label, FormText } from 'reactstrap';
import { ERHRadioBtn } from '../../Components/RadioButton';
import { ERHCheckbox } from '../../Components/Checkbox';
import { ERHRangeSlider } from '../../Components/RangeSlider';
import { ERHSelect } from '../../Components/Select';
import { ERHButton } from '../../Components/Button';
import { ERHInput } from '../../Components/Input';

import { typeOfFunding } from '../Utils/constants';
import { optionsTimeInBusiness, optionsRevenue, formatAmount, optionsHowDidYouHearAboutUs, validateWebsite } from '../../helpers';
import { socialMediaOptions } from '../Utils/constants';

const BusinessInformation = ({
    data,
    dataDispatch,
    dataTypes,
    handleSingleSelect,
    handleTypeOfFunding,
    handleSocialMedia,
    handleSelect,
    handleOnClick,
    goBack,
    stepCompleted,
    otherOptionErhOpportunity,
    erhOpportunityInput,
    setErhOpportunityInput
}) => {
    return (
        <> 
            <Col xs={12}>
                <ERHSelect placeholder='Select number of years' 
                name='years_in_business' 
                options={optionsTimeInBusiness} 
                id='years_in_business'
                classContainer={'my-2'}
                label='Years in business*'
                onChange={handleSingleSelect}
                />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>How much did you have in annual revenue the last year? *</Label> 
                    <ERHSelect
                    classContainer={'my-2'}
                    classNamePrefix="select"
                    options={optionsRevenue}
                    id='annual_revenue' 
                    name='annual_revenue'
                    onChange={handleSingleSelect}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Type of funding you are seeking*</Label>
                    { typeOfFunding.map(({name, label}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={name}
                            key={name}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleTypeOfFunding}
                        />
                    ))}
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>How did you learn about EnrichHER or this opportunity? *</Label> 
                { socialMediaOptions.map(({label, name, key}) => (
                    <ERHCheckbox
                        name={name}
                        label={label}
                        id={key}
                        key={key}
                        classContainer={'my-2 px-3'}
                        styled={true}
                        onChange={handleSocialMedia}
                    />
                ))}   
            </Col>
            <Col xs={12}>
                { otherOptionErhOpportunity && (
                    <ERHInput
                        name='opportunity_input'
                        type='text'
                        id='opportunity_input'
                        value={erhOpportunityInput}
                        placeholder='Enter another option'
                        classContainer='my-2'
                        autoComplete='disabled'
                        onChange={(e) => setErhOpportunityInput( e.target.value )}
                        maxLength='250'
                    />
                )}
            </Col>
            <Col xs={12} className='mt-3 mb-5'>
            <Label className='label-input mb-0'>How you learned about EnrichHER continued</Label> 
                <FormText tag='medium' className='mt-0'>If you learned about us from Social Media, please identify which platform below - LinkedIn, Facebook, Instagram or Twitter. </FormText>
                <FormText className='mt-3' tag='medium'>If you learned about us from a partner organization affiliated with EnrichHER, such as Hello ALICE or Women 2.0, please identify which partner below. </FormText>
                <ERHSelect
                name='social_media_channels'
                tooltip={'How You Learned About EnrichHER Continued *'}
                id='social_media_channels' isMulti
                options={optionsHowDidYouHearAboutUs}
                onChange={handleSelect}
                />
            </Col>
            <Col>
            <h3 className='title-form mb-3'>Digital Presence</h3> 
            <div className={'introductory-div'}></div>
            </Col>
            <Col xs={12} className='mt-2'>
                <Label className='label-input mb-0'>What is your website?</Label>
                <FormText tag='medium' className='mt-0'>Please use the primary website that people use to learn about your business. A social media website is OK.</FormText>
                    <ERHInput 
                        placeholder='Your website'
                        name='website'
                        type='text'
                        id='website'
                        value={data.website}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setWebsite, payload: e.target.value })}
                        error={data.website && !validateWebsite(data.website) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your business Instagram page?</Label>
                <FormText tag='medium' className='mt-0'>Please list the URL for the Instagram page of your business.</FormText>
                    <ERHInput 
                        placeholder='Your business Instagram page'
                        name='business_instagram'
                        type='text'
                        id='business_instagram'
                        value={data.business_instagram}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setBusinessInstagram, payload: e.target.value })}
                        error={data.business_instagram && !validateWebsite(data.business_instagram) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your business Facebook page?</Label>
                <FormText tag='medium' className='mt-0'>Please list the URL for the Facebook page of your business.</FormText>
                    <ERHInput 
                        placeholder='Your business Facebook page'
                        name='business_facebook'
                        type='text'
                        id='business_facebook'
                        value={data.business_facebook}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setBusinessFacebook, payload: e.target.value })}
                        error={data.business_facebook && !validateWebsite(data.business_facebook) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your business LinkedIn page?</Label>
                <FormText tag='medium' className='mt-0'>Please list the URL for the LinkedIn page of your business.</FormText>
                    <ERHInput 
                        placeholder='Your business LinkeIn page'
                        name='business_linkedin'
                        type='text'
                        id='business_linkedin'
                        value={data.business_linkedin}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setBusinessLinkedin, payload: e.target.value })}
                        error={data.business_linkedin && !validateWebsite(data.business_linkedin) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your personal Instagram page?</Label>
                <FormText tag='medium' className='mt-0'>Please list the URL for your personal Instagram page if it is helpful for learning more about you.</FormText>
                    <ERHInput 
                        placeholder='Your personal Instagram page'
                        name='personal_instagram'
                        type='text'
                        id='personal_instagram'
                        value={data.personal_instagram}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setPersonalInstagram, payload: e.target.value })}
                        error={data.personal_instagram && !validateWebsite(data.personal_instagram) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your personal Facebook page?</Label>
                <FormText tag='medium' className='mt-0'>Please list the URL of your personal Facebook page if it is helpful for learning more about you.</FormText>
                    <ERHInput 
                        placeholder='Your personal Facebook page'
                        name='personal_facebook'
                        type='text'
                        id='personal_facebook'
                        value={data.personal_facebook}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setPersonalFacebook, payload: e.target.value })}
                        error={data.personal_facebook && !validateWebsite(data.personal_facebook) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your personal LinkedIn page?</Label>
                <FormText tag='medium' className='mt-0'>Please list the URL for your personal LinkedIn page if it is helpful for learning more about you.</FormText>
                    <ERHInput 
                        placeholder='Your personal LinkeIn page'
                        name='personal_linkedin'
                        type='text'
                        id='personal_linkedin'
                        value={data.personal_linkedin}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setPersonalLinkedin, payload: e.target.value })}
                        error={data.personal_linkedin && !validateWebsite(data.personal_linkedin) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is another online resource to help to learn about you (YouTube Video, News Article, Blog Article)?</Label>
                <FormText tag='medium' className='mt-0'>Please list the URL to any page that highlights your work.</FormText>
                    <ERHInput 
                        placeholder='Another online resource'
                        name='online_resource'
                        type='text'
                        id='online_resource'
                        value={data.online_resource}
                        classContainer='my-2'
                        onChange={(e) => dataDispatch({ type: dataTypes.setOnlineResource, payload: e.target.value })}
                        error={data.online_resource && !validateWebsite(data.online_resource) && 'Please enter a valid URL starting with http://.'}
                    />
            </Col>
            <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
            <ERHButton
                className='my-2'
                onClick={goBack}
                disabled={false}
            >
                Go back
            </ERHButton>
            <ERHButton
                className='my-2'
                onClick={handleOnClick}
                disabled={!stepCompleted()}
            >
                Continue
            </ERHButton>
        </div>
        </>
    )
}

export default BusinessInformation
