import React, { useState, useEffect } from 'react';
import BusinessInformation  from '../Components/BusinessInformation';
import { Row } from 'reactstrap';

import { useDispatch, useStore } from '../Stores/FormStore/FormProvider';
import { type } from '../Stores/FormStore/FormReducer';
import { useData, useDataDispatch } from '../Stores/Data/acceleratorContext';
import { dataTypes } from '../Stores/Data/acceleratorReducer';

import { optionsTimeInBusiness, optionsRevenue } from '../../helpers';
import {
    secondStepValidation,
    socialMediaOptionsState,
    typeOfFundingOptionsState
} from '../Utils/constants';

const StepTwo = ({ setPageHeight }) => {
    const dataDispatch = useDataDispatch();
    const data = useData();
    const dispatch = useDispatch();
    const { currentStep } = useStore();
    const pageDisabled = currentStep !== 1;

    const [validInput, setValidInput] = useState({...secondStepValidation});

    const [socialMediaState, setSocialMediaState] = useState({...socialMediaOptionsState});
    const [typeOfFundingState, setTypeOfFundingState] = useState({...typeOfFundingOptionsState});
    const [otherOptionErhOpportunity, setOtherOptionErhOpportunity] = useState(false);
    const [erhOpportunityInput, setErhOpportunityInput] = useState('');
    
    useEffect(() => !pageDisabled && setPageHeight('step2'));

    useEffect(() => {
        const socialMediaSelected = Object.keys(socialMediaState).filter(filterName => socialMediaState[filterName]);
        dataDispatch({
            type: dataTypes.setSocialMedia, 
            payload: erhOpportunityInput !== '' ?
            socialMediaSelected.concat(erhOpportunityInput) :
            socialMediaSelected
        });
        socialMediaSelected.length >= 1 ? 
        setValidInput({ ...validInput, social_media: true}) :
        setValidInput({ ...validInput, social_media: false})

        erhOpportunityInput !== '' ? setValidInput({ ...validInput, social_media: true}) :
        socialMediaSelected >= 1 && setValidInput({ ...validInput, social_media: true})
    }, [socialMediaState, erhOpportunityInput]);

    useEffect(() => {
        const typeOfFundingSelected = Object.keys(typeOfFundingState).filter(filterName => typeOfFundingState[filterName]);
        dataDispatch({ type: dataTypes.setTypeOfFunding, payload: typeOfFundingSelected});
        typeOfFundingSelected.length >= 1 ? 
        setValidInput({ ...validInput, type_of_funding: true}) :
        setValidInput({ ...validInput, type_of_funding: false})
    }, [typeOfFundingState]);

    const handleSocialMedia = (e) => {
        const { name, checked } = e.target;
        name !== 'Other' 
        ?
        setSocialMediaState({
            ...socialMediaState,
            [name]: checked
        })
        :
        setOtherOptionErhOpportunity(checked);
        name === 'Other' && !checked && setErhOpportunityInput('');
    }

    const handleTypeOfFunding = (e) => {
        const { name, checked } = e.target;
        setTypeOfFundingState({
            ...typeOfFundingState,
            [name]: checked
        });
    }

    const handleSelect = (inputValue) => {
        const value = inputValue ? inputValue.map(v => v.value) : []
        dataDispatch({ type: dataTypes.setSocialMediaChannels, payload: value });
    }

    const handleSingleSelect = (inputValue) => {
        const yearsInBusiness = optionsTimeInBusiness.find(v => v.label === inputValue.label);
        const annualRevenue = optionsRevenue.find(v => v.label === inputValue.label);
        if(yearsInBusiness) {
            dataDispatch({ type: dataTypes.setYearsInBusiness, payload: yearsInBusiness.label });
            setValidInput({ ...validInput, years_in_business: true });
        }
        if(annualRevenue) {
            dataDispatch({ type: dataTypes.setAnnualRevenue, payload: annualRevenue.label });
            setValidInput({ ...validInput, annual_revenue: true });
        }
    }

    const handleOnClick = (e) => {
        e.preventDefault();
        dispatch({ type: type.nextStep });
    }

    const goBack = (e) => {
        e.preventDefault()
        dispatch({ type: type.prevStep });
    }

    const stepCompleted = () => Object.values(validInput).every(value => value === true);
    const requiredFields = Object.keys(validInput).filter(filterName => validInput[filterName]);
    
    return (
        <div className={`fragment-form-application ${pageDisabled && 'inactive'}`}>
            <div className='form' id='step2'>
                <Row>
                    <BusinessInformation 
                    data={data} 
                    dataDispatch={dataDispatch} 
                    dataTypes={dataTypes} 
                    handleSingleSelect={handleSingleSelect}
                    handleTypeOfFunding={handleTypeOfFunding}
                    handleSocialMedia={handleSocialMedia}
                    handleSelect={handleSelect}
                    handleOnClick={handleOnClick}
                    goBack={goBack}
                    stepCompleted={stepCompleted}
                    requiredFields={requiredFields}
                    otherOptionErhOpportunity={otherOptionErhOpportunity}
                    erhOpportunityInput={erhOpportunityInput}
                    setErhOpportunityInput={setErhOpportunityInput}
                    />
                </Row>
                <p className='error-input' style={{ textAlign: 'right' }}>{requiredFields.length === 6 ? '' : 'Please complete all required fields above in order to continue'}</p>
            </div>
        </div>
    )
}

export default StepTwo
