import React, { createContext, useContext, useReducer } from 'react';
import dataReducer, { initialState } from './acceleratorReducer';

const AcceleratorContext = createContext();

const AcceleratorProvider = ({ children }) => {
    return <AcceleratorContext.Provider value={useReducer(dataReducer, initialState)}>
        { children }
    </AcceleratorContext.Provider>
}

const useData = () => useContext(AcceleratorContext)[0];
const useDataDispatch = () => useContext(AcceleratorContext)[1];

export { AcceleratorContext, useData, useDataDispatch }
export default AcceleratorProvider;
